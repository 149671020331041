import React from 'react'
import ContentContainer from '../Components/ContentContainer'

const Beers = () => {
  return (
    <ContentContainer>
      <h1>Beers</h1>
    </ContentContainer>
  )
}

export default Beers
